import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";
import AddModal from "./components/AddModal";
import DeleteModal from "./components/DeleteModal";
import EditModal from "./components/EditModal";
import CAlertModal from "../../components/Custom/CAlertModal";
import LoadingModal from "../../components/Custom/Loading";
import useData from "./data";
import Filters from "./Filters";
import PasswordModal from "./components/PasswordModal";

export default function Propietarios() {
    const {
        apartments,
        tenantsList,
        residential,
        setModel,
        cAlert,
        setCAlert,
        onChange,
        onCreate,
        onUpdate,
        onDelete,
        openDelete,
        openEdit,
        open,
        setOpen,
        setOpenDelete,
        setOpenEdit,
        model,
        loading,
        filtersValues,
        filtersOnchange,
        onFilters,
        openPassword,
        setOpenPassword,
        password,
        setPassword,
        onChangePassword,
    } = useData();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Filters residential={residential} values={filtersValues} onChange={filtersOnchange} onClick={onFilters}/>
            <MDBox mt={4}>
                <DataTable
                    onClick={() => setOpen(true)}
                    canSearch
                    table={{
                        columns: [
                            {Header: "nombre completo", accessor: "tenant"},
                            {Header: "residencial", accessor: "residential.label"},
                            {Header: "apt", accessor: "apartment.label"},
                            {Header: "contacto", accessor: "phoneNumber"},
                            {Header: "correo", accessor: "mail"},
                            {Header: "comentario", accessor: "comment"},
                            {accessor: "actions",},
                        ],
                        rows: tenantsList,
                    }}
                />
            </MDBox>
            <AddModal
                residential={residential}
                apartments={apartments}
                onChange={onChange}
                onCreate={onCreate}
                open={open}
                handleClose={() => setOpen(false)}
                model={model}
                setModel={setModel}
            />
            <EditModal
                apartments={apartments}
                residential={residential}
                onChange={onChange}
                onCreate={onUpdate}
                open={openEdit}
                handleClose={() => setOpenEdit(false)}
                model={model}
                setModel={setModel}
            />
            <PasswordModal password={password} setPassword={setPassword} open={openPassword}
                           handleClose={() => setOpenPassword(false)} onCreate={onChangePassword}/>
            <DeleteModal onDelete={onDelete} open={openDelete} handleClose={() => setOpenDelete(false)}/>
            <CAlertModal alert={cAlert} close={setCAlert}/>
            <LoadingModal loading={loading}/>
        </DashboardLayout>
    );
}
