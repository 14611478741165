import React from "react";
import {useFirebase} from "react-redux-firebase";
import {TableActions} from "./components/Table.Actions";
import {AlertModel, FiltersModel, IncomeModel} from "../../model";
import {DateTime} from "luxon";
import {sendReceipt} from "../../utilities/constants";
import {months} from "../../utilities/constants";

const useData = () => {
    const firebase = useFirebase();
    const [model, setModel] = React.useState(new IncomeModel());
    const [cAlert, setCAlert] = React.useState(new AlertModel());
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [modalSendMail, setModalSendMail] = React.useState(false);
    const [income, setIncome] = React.useState([]);
    const [residential, setResidential] = React.useState([]);
    const [apartments, setApartments] = React.useState([]);
    const [bank, setBank] = React.useState([]);
    const [monthsList, setMonthsList] = React.useState(months);
    const [numberOfMonths, setNumberOfMonths] = React.useState(0);
    const [dueDate, setDueDate] = React.useState(null);
    const [filtersValues, setFiltersValues] = React.useState(new FiltersModel());
    const [filtersData, setFiltersData] = React.useState(null);


    const onCancel = () => {
        setModalSendMail(false)
        setModel(new IncomeModel())
    }

    React.useEffect(() => {
        const db = firebase.firestore();
        db.collection("income")
            .orderBy("createdAt", "desc")
            .onSnapshot((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    list.push(data);
                });
                setIncome(list);
            });
        db.collection("residential")
            .orderBy("residential", "asc")
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.label = data.residential;
                    list.push(data);
                });
                setResidential(list);
            });
        db.collection("bank")
            .orderBy("bank", "asc")
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.label = data.bank;
                    list.push(data);
                });
                setBank(list);
            });
    }, []);

    React.useEffect(()=>{
        if (model.residential && model.residential.id){
            const db = firebase.firestore();
            db.collection("apartments")
                .where('residential.id', '==', model.residential.id)
                .orderBy("apartment", "asc")
                .get()
                .then((docs) => {
                    const list = [];
                    docs.forEach((doc) => {
                        const data = doc.data();
                        data.id = doc.id;
                        data.label = data.apartment;
                        list.push(data);
                    });
                    setApartments(list);
                });
        }
    },[model])

    const actions = {
        onEdit: (data) => {
            data.amount = data.maintenanceCost
            setModel(data);
            setOpenEdit(true);
        },
        onDelete: (data) => {
            setModel(data);
            setOpenDelete(true);
        },
        onSendMail: async (data) => {
            let mail = data.tenant.mail
            setModel({...data, mail: mail ?? ''});
            setModalSendMail(true);
        },
    };


    const onConfirmSendMail = async () => {
        try {
            setLoading(true)
            setModalSendMail(false)
            let data ={...model}
            let amount = data.amount
            let paymentDate = DateTime.fromSeconds(data.createdAt.seconds).toFormat('ff')
            let mail = data.mail
            let result = await sendReceipt(mail, amount, paymentDate)
            setLoading(false)
            onCancel()
            if (result) {
                setCAlert({open: true, type: "success", ms: "Factura enviada con éxito"});
            } else {
                setCAlert({open: true, type: "error", ms: 'Error al enviar factura'});
            }
        } catch (e) {
            setCAlert({open: true, type: "error", ms: e.message});
        }
    }


    const onCreate = async () => {
        try {
            let amount = model.amount * monthsList.filter(m => m.selected).length
            let paymentDate = DateTime.now().toFormat('ff')
            let mail = model.apartment.tenant.mail
            setLoading(true);
            setOpen(false);
            const db = firebase.firestore();
            let data = {
                createdBy: {
                    id: firebase.auth().currentUser.uid,
                    displayName: firebase.auth().currentUser.displayName,
                },
                maintenanceCost:model.residential.maintenanceCost,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                tenant: model.apartment.tenant,
                apartment: {id: model.apartment.id, label: model.apartment.label},
                residential: {id: model.residential.id, label: model.residential.label},
                comment: model.comment,
                amount: amount,
                bank: model.bank,
                monthsPaid: monthsList.filter(m => m.selected).map(ms => {
                    return {id: ms.id, label: ms.label}
                }),
            }

            let ref = db.collection('apartments').doc(model.apartment.id)
            await db.collection("income").add(data)
            await ref.update({
                paymentStatus: 'completed',
                dueDate: dueDate
            })
            setCAlert({open: true, type: "success", ms: "Ingreso Agregado Correctamente"});
            await sendReceipt(mail, amount, paymentDate)
            setLoading(false);
            setModel(new IncomeModel());
            setMonthsList(months)
            setNumberOfMonths(0)
        }catch (e){
            setCAlert({open: true, type: "error", ms: e.message});
        }
    };


    const onUpdate = async () => {
        try {
            setLoading(true);
            setOpenEdit(false);
            const db = firebase.firestore();
            await db.collection("income")
                .doc(model.id)
                .update({
                    updatedBy: {
                        id: firebase.auth().currentUser.uid,
                        displayName: firebase.auth().currentUser.displayName,
                    },
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    apartment: {id: model.apartment.id, label: model.apartment.label},
                    tenant: model.tenant,
                    residential: {id: model.residential.id, label: model.residential.label},
                    comment: model.comment,
                    amount: model.amount * monthsList.filter(m => m.selected).length,
                    bank: model.bank,
                    monthsPaid: monthsList.filter(m => m.selected).map(ms => {
                        return {id: ms.id, label: ms.label}
                    }),
                })
            let ref = db.collection('apartments').doc(model.apartment.id)
            await ref.update({
                dueDate: dueDate
            })
            setCAlert({open: true, type: "success", ms: "Datos Del Ingreso Actualziado"});
            setLoading(false);
            setModel(new IncomeModel());
        } catch (e) {
            setCAlert({open: true, type: "error", ms: e.message});
        }
    };

    const onDelete = () => {
        setOpenDelete(false);
        const db = firebase.firestore();
        db.collection("income")
            .doc(model.id)
            .delete()
            .then(() => {
                setCAlert({open: true, type: "success", ms: "Ingreso Eliminado"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new IncomeModel());
            });
    };

    const onChange = (e, select) => {
        if (!select) {
            const {name, value} = e.target;
            setModel((prevState) => ({...prevState, [name]: value}));
        } else {
            const selected = {...select};
            const name = selected.autocomplete;
            delete selected.autocomplete;

            if (name==='residential'){
                setModel((prevState) => ({...prevState, amount: selected.maintenanceCost}));
            }
            setModel((prevState) => ({...prevState, [name]: selected}));
        }
    };


    const filtersOnchange = (e, select) => {
        if (!select) {
            const {name, value} = e.target;
            setFiltersValues((prevState) => ({...prevState, [name]: value}));
        } else {
            const selected = {...select};
            const name = selected.autocomplete;
            delete selected.autocomplete;
            setFiltersValues((prevState) => ({...prevState, [name]: selected.value || selected}));
        }
    }


    const onSelectMonth = (item) => {
        let month =[...monthsList]
       let index = months.findIndex((obj => obj.id === item.id));
        month[index].selected = !item.selected
        let selected= month.filter(m=>m.selected)
        setNumberOfMonths(selected.length)
        let lastMonths = {id:0}
        if (selected.length>0){
            lastMonths = selected.reduce((prev, current) => (prev.id > current.id) ? prev : current)
        }

        let billingDay = model.residential.billingDay

        let nextMonths;
        let year;
        let due;

        if (lastMonths.id === 12) {
            year = new Date().getFullYear() + 1
            due = DateTime.fromFormat(`${year}-${1}-${billingDay}`, 'yyyy-M-d').toJSDate()
        } else {
            nextMonths = lastMonths.id + 1
            year = new Date().getFullYear()
            due = DateTime.fromFormat(`${year}-${nextMonths}-${billingDay}`, 'yyyy-M-d').toJSDate()
        }

        setDueDate(due)
        setMonthsList(month)
    }

    const onFilters = () => {
        const db = firebase.firestore();
        let from = DateTime.fromFormat(filtersValues.from, 'yyyy-MM-dd').toJSDate()
        let to = DateTime.fromFormat(filtersValues.to, 'yyyy-MM-dd').toJSDate()
        db.collection("income")
            .where("createdAt", ">=", from)
            .where("createdAt", "<=", to)
            .where('residential.id', '==', filtersValues.residential.id)
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    list.push(data);
                });
                setFiltersData(list);
            });
    }


    const incomeList = React.useMemo(() => {
        return (filtersData? filtersData : income).map((data) => ({
            ...data,
            id: data.id,
            createdAt: data.createdAt? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '',
            actions: <TableActions data={data} {...actions} />,
        }));

    }, [filtersData, income])

    return {
        bank,
        residential,
        apartments,
        income,
        incomeList,
        model,
        setModel,
        openDelete,
        openEdit,
        open,
        setOpen,
        setOpenDelete,
        setOpenEdit,
        cAlert,
        setCAlert,
        loading,
        onChange,
        onCreate,
        onUpdate,
        onDelete,
        filtersValues,
        filtersOnchange,
        onFilters,
        monthsList,
        onSelectMonth,
        numberOfMonths,
        dueDate,
        onConfirmSendMail,
        modalSendMail,
        onCancel
    };
};

export default useData;
