import React from "react";
import {useFirebase} from "react-redux-firebase";
import {AlertModel, FiltersModel} from "../../model";
import {DateTime, Settings} from "luxon";
import {PdfFile} from "./components/PdfFile";
Settings.defaultLocale = 'es-do'

const useData = () => {
    const firebase = useFirebase();
    const [cAlert, setCAlert] = React.useState(new AlertModel());
    const [loading, setLoading] = React.useState(false);
    const [modalValidate, setModalValidate] = React.useState(false);
    const [income, setIncome] = React.useState([]);
    const [othersIncome, setOthersIncome] = React.useState([]);
    const [expenses, setExpenses] = React.useState([]);
    const [gas, setGas] = React.useState([]);
    const [gasValue, setGasValue] = React.useState(0);
    const [previousDue, setPreviousDue] = React.useState([]);
    const [incomeValue, setIncomeValue] = React.useState(0);
    const [previousDueValue, setPreviousDueValue] = React.useState(0);
    const [othersIncomeValue, setOthersIncomeValue] = React.useState(0);
    const [expensesValue, setExpensesValue] = React.useState(0);
    const [balance, setBalance] = React.useState(0);
    const [isBalance, setIsBalance] = React.useState(true);
    const [lastValidation, setLastValidation] = React.useState(null);
    const [balanceId, setBalanceId] = React.useState('');
    const [filtersValues, setFiltersValues] = React.useState(new FiltersModel());
    const [residential, setResidential] = React.useState([]);


    React.useEffect(() => {
        const db = firebase.firestore();
        db.collection("residential")
            .orderBy("residential", "asc")
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.label = data.residential;
                    list.push(data);
                });
                setResidential(list);
            });
    }, [])

    const filtersOnchange = (e, select) => {
        if (!select) {
            const {name, value} = e.target;
            setFiltersValues((prevState) => ({...prevState, [name]: value}));
        } else {
            const selected = {...select};
            const name = selected.autocomplete;
            delete selected.autocomplete;
            setFiltersValues((prevState) => ({...prevState, [name]: selected.value || selected}));
        }
    }

    const onDownloadPdf = () => {
        setLoading(true)
        setTimeout(()=>{
            let pdfData = [...othersIncome, ...expenses, ...income, ...previousDue, ...gas]
            PdfFile(
                onSort(pdfData),
                filtersValues,
                incomeValue,
                othersIncomeValue,
                expensesValue,
                balance,
                setLoading,
                previousDueValue,
                gasValue
            )
        },1000)

    }

    const onFilters = () => {
        setLoading(true)
        const db = firebase.firestore();
        let from = DateTime.fromFormat(filtersValues.from, 'yyyy-MM-dd').toJSDate()
        let to = DateTime.fromFormat(filtersValues.to, 'yyyy-MM-dd').toJSDate()
        db.collection("income")
            .orderBy("createdAt", "desc")
            .where("createdAt", ">=", from)
            .where("createdAt", "<=", to)
            .where('residential.id', '==', filtersValues.residential.id)
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.income = Number(data.amount);
                    data.detail = `${data.apartment.label} ${data.comment}`;
                    data.date = data.createdAt? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '';
                    list.push(data);
                });
                setIncomeValue(list.reduce(
                    (prev, c) => prev + Number(c.amount),
                    0
                ))
                setLoading(false)
                setIncome(list);
            });


        db.collection("previous_due")
            .orderBy("createdAt", "desc")
            .where("createdAt", ">=", from)
            .where("createdAt", "<=", to)
            .where('residential.id', '==', filtersValues.residential.id)
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.previousDue = Number(data.amount);
                    data.detail = `${data.apartment.label} ${data.comment}`;
                    data.date = data.createdAt? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '';
                    list.push(data);
                });
                setPreviousDueValue(list.reduce(
                    (prev, c) => prev + Number(c.amount),
                    0
                ))
                setPreviousDue(list);
            });

        db.collection("othersIncome")
            .orderBy("createdAt", "desc")
            .where("createdAt", ">=", from)
            .where("createdAt", "<=", to)
            .where('residential.id', '==', filtersValues.residential.id)
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.othersIncome = Number(data.amount);
                    data.detail = `${data.apartment.label} ${data.comment}`;
                    data.date = data.createdAt? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '';
                    list.push(data);
                });
                setOthersIncomeValue(list.reduce(
                    (prev, c) => prev + Number(c.amount),
                    0
                ))
                setOthersIncome(list);
            });

        db.collection("expenses")
            .orderBy("completedAt", "desc")
            .where("completedAt", ">=", from)
            .where("completedAt", "<=", to)
            .where('residential.id', '==', filtersValues.residential.id)
            .where('paymentStatus.id', '==', 'completed')
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.detail = data.concept;
                    data.expenses = Number(data.amount);
                    data.date = data.createdAt ? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '';
                    list.push(data);
                });

                setExpensesValue(list.reduce(
                    (prev, c) => prev + Number(c.amount),
                    0
                ))
                setExpenses(list);
            }).catch(e => {})

        db.collection("gas")
            .orderBy("completedAt", "desc")
            .where("completedAt", ">=", from)
            .where("completedAt", "<=", to)
            .where('residential.id', '==', filtersValues.residential.id)
            .where('paymentStatus', '==', 'completed')
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.detail = data.concept;
                    data.detail = `${data.apartment.label}`;
                    data.gas = Number(data.amount);
                    data.date = data.createdAt ? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '';
                    list.push(data);
                });

                setGasValue(list.reduce(
                    (prev, c) => prev + Number(c.amount),
                    0
                ))
                setGas(list);
            })

        db.collection("balance")
            .where('residential.id', '==', filtersValues.residential.id)
            .get()
            .then((docs) => {
                if (!docs.empty){
                    let data = docs.docs[0].data()
                    setBalanceId(docs.docs[0].id)
                    setBalance(data.balance)
                    setLastValidation(data.updatedAt? data.updatedAt.seconds:null)
                    setIsBalance(true)
                }else {
                    setBalance(0)
                    setIsBalance(false)
                }
            });
    }


    const onValidateAccounting = async () => {
        setModalValidate(false)
        setLoading(true)
        try {
            let prevBalance = incomeValue + previousDueValue + gas +  othersIncomeValue + Number(balance) - expensesValue
            const db = firebase.firestore();
            await db.collection("balance")
                .doc(balanceId)
                .update({
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    balance: prevBalance
                })
            await db.collection("balance")
                .doc(balanceId)
                .collection('records')
                .add({
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    balance: prevBalance,
                    income: incomeValue,
                    gas: gasValue,
                    previousDueValue: previousDueValue,
                    previousBalance: Number(balance),
                    expenses: expensesValue
                })
            setLoading(false)
        } catch (e) {
        }
    }

    const onSort = (array) => {
        return array.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
        });
    }

    const data = React.useMemo(() => {
        let array =[...othersIncome, ...expenses, ...income, ...previousDue, ...gas].map((data) => ({
            ...data,
            id: data.id,
            createdAt: data.createdAt ? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '',
        }));
        return onSort(array)
    }, [ income, expenses, othersIncome, previousDue, gas])

    return {
        data:data,
        cAlert,
        setCAlert,
        loading,
        filtersValues,
        filtersOnchange,
        onFilters,
        incomeValue,
        previousDueValue,
        gasValue,
        othersIncomeValue,
        expensesValue,
        residential,
        balance,
        setModalValidate,
        modalValidate,
        onValidateAccounting,
        onDownloadPdf,
        isBalance,
        lastValidation,
    };
};

export default useData;
