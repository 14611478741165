import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";
import useData from "./data";
import Filters from "./Filters";
import Summary from "./components/Summary";
import ValidateModal from "./components/ValidateModal";
import LoadingModal from "../../components/Custom/Loading";

export default function RegistroContable() {
    const {
        loading,
        data,
        filtersValues,
        filtersOnchange,
        onFilters,
        onDownloadPdf,
        onValidateAccounting,
        incomeValue,
        othersIncomeValue,
        expensesValue,
        residential,
        balance,
        setModalValidate,
        modalValidate,
        isBalance,
        lastValidation,
        previousDueValue,
        gasValue
    } = useData();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Filters onDownloadPdf={onDownloadPdf} incomeValue={incomeValue} residential={residential} values={filtersValues} onChange={filtersOnchange} onClick={onFilters}/>
            <Summary gasValue={gasValue} loading={loading} previousDueValue={previousDueValue} othersIncomeValue={othersIncomeValue} lastValidation={lastValidation} isBalance={isBalance} incomeValue={incomeValue} expensesValue={expensesValue} balance={balance} onClick={()=>setModalValidate(true)}/>
            <MDBox mt={4}>
                <DataTable
                    canSearch
                    table={{
                        columns: [
                            {Header: "Fecha", accessor: "date"},
                            {Header: "Detalles", accessor: "detail"},
                            {Header: "Ingresos", accessor: "income"},
                            {Header: "Gas", accessor: "gas"},
                            {Header: "Deuda anterior", accessor: "previousDue"},
                            {Header: "Otros ingresos", accessor: "othersIncome"},
                            {Header: "Gastos", accessor: "expenses"}
                        ],
                        rows: data,
                    }}
                />
            </MDBox>
            <LoadingModal loading={loading}/>
            <ValidateModal gasValue={gasValue} previousDueValue={previousDueValue} othersIncomeValue={othersIncomeValue} onSuccess={onValidateAccounting} filtersValues={filtersValues} incomeValue={incomeValue} expensesValue={expensesValue} balance={balance} open={modalValidate} handleClose={()=>setModalValidate(false)}/>
        </DashboardLayout>
    );
}
