import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Modal, Typography } from "@mui/material";
import React from "react";
import FormField from "../../../components/Custom/FormField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export default function EditModal({ open, handleClose, onCreate, model, onChange }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={style}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          ACTUALIZAR DATOS DEL PROVEEDOR
        </Typography>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.provider}
            name="provider"
            onChange={onChange}
            fontWeight="bold"
            label="Nombre | Razón Social"
            placeholder="Edenorte, S.A."
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.phoneNumber}
            name="phoneNumber"
            onChange={onChange}
            fontWeight="bold"
            label="Numero Telefonico"
            placeholder="809-583-1844"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormField
            value={model.comment}
            name="comment"
            onChange={onChange}
            fontWeight="bold"
            label="Comentario"
            placeholder="La Fecha de Pago es del 1-10"
          />
        </Grid>
        <br />
        <MDButton style={{ marginRight: 5 }} color="success" onClick={onCreate}>
          Actulizar
        </MDButton>
        <MDButton onClick={handleClose} color="error">
          Cancelar
        </MDButton>
      </MDBox>
    </Modal>
  );
}
