import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";
import AddModal from "./components/AddModal";
import DeleteModal from "./components/DeleteModal";
import EditModal from "./components/EditModal";
import CAlertModal from "../../components/Custom/CAlertModal";
import LoadingModal from "../../components/Custom/Loading";
import useData from "./data";
import Filters from "../income/Filters";

export default function Expenses() {
    const {
        bank,
        residential,
        gasList,
        apartments,
        setModel,
        cAlert,
        setCAlert,
        onChange,
        onCreate,
        onUpdate,
        onDelete,
        openDelete,
        openEdit,
        open,
        setOpen,
        handleClose,
        model,
        loading,
        filtersValues,
        filtersOnchange,
        onFilters,
    } = useData();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Filters residential={residential} apartments={apartments} bank={bank} values={filtersValues} onChange={filtersOnchange} onClick={onFilters}/>
            <MDBox mt={4}>
                <DataTable
                    onClick={() => setOpen(true)}
                    canSearch
                    table={{
                        columns: [
                            {Header: "Creado en", accessor: "createdAt"},
                            {Header: "Residencial.", accessor: "residential.label"},
                            {Header: "apt", accessor: "apartment.label"},
                            {Header: "gal", accessor: "gallonsQty"},
                            {Header: "p/gal", accessor: "price"},
                            {Header: "Importe", accessor: "amount"},
                            {Header: "Estado de pago", accessor: "paymentStatus"},
                            {Header: "via", accessor: "bank.label"},
                            {accessor: "actions"},
                        ],
                        rows: gasList,
                    }}
                />
            </MDBox>
            <AddModal
                residential={residential}
                bank={bank}
                apartments={apartments}
                onChange={onChange}
                onCreate={onCreate}
                open={open}
                handleClose={handleClose}
                model={model}
                setModel={setModel}
            />
            <EditModal
                residential={residential}
                bank={bank}
                apartments={apartments}
                onChange={onChange}
                onCreate={onUpdate}
                open={openEdit}
                handleClose={handleClose}
                model={model}
                setModel={setModel}
            />
            <DeleteModal onDelete={onDelete} open={openDelete} handleClose={handleClose}/>
            <CAlertModal alert={cAlert} close={setCAlert}/>
            <LoadingModal loading={loading}/>
        </DashboardLayout>
    );
}
