import {firestore} from "../../firebase";

export const GET_SUMMARY = 'GET_SUMMARY';
export function success(payload) {
  return {
    type: GET_SUMMARY,
    payload,
  };
}

export function getSummary() {
  return async function (dispatch) {
    const usersOnSnapshot = firestore
      .collection('summary')
      .doc('data')
      .onSnapshot(async querySnapshot => {
        if (!querySnapshot) {
          return;
        }
        if (querySnapshot.data() !== undefined) {
          let data = querySnapshot.data();
          dispatch(success({...data, subscribed: usersOnSnapshot}));
        }
      });
  };
}
