import React from "react";
import {useFirebase} from "react-redux-firebase";
import {TableActions} from "./components/Table.Actions";
import {AlertModel, ApartmentsModel, FiltersModel} from "../../model";
import {DateTime} from "luxon";
import {returnLabel} from "../../utilities/constants";
import {string} from "prop-types";

const useData = () => {
    const firebase = useFirebase();
    const [model, setModel] = React.useState(new ApartmentsModel());
    const [cAlert, setCAlert] = React.useState(new AlertModel());
    const [filtersValues, setFiltersValues] = React.useState(new FiltersModel());
    const [filtersData, setFiltersData] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const [modalRecordPayments, setModalRecordPayments] = React.useState(false);
    const [recordPayments, setRecordPayments] = React.useState([]);
    const [tenants, setTenants] = React.useState([]);
    const [residential, setResidential] = React.useState([]);
    const [apartments, setApartments] = React.useState([]);

    React.useEffect(() => {
        const db = firebase.firestore();
        db.collection("tenants")
            // .where('status', '==', 'active')
            .orderBy("tenant", "asc")
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.label = data.tenant;
                    list.push(data);
                });
                setTenants(list);
            });

        db.collection("residential")
            // .where('status', '==', 'active')
            .orderBy("residential", "asc")
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.label = data.residential;
                    list.push(data);
                });
                setResidential(list);
            });

        db.collection("apartments")
            // .where('status', '==', 'active')
            .orderBy("createdAt", "desc")
            .onSnapshot((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    list.push(data);
                });
                setApartments(list);
            });
    }, []);

    const onSummary = (name, n) => {
        const db = firebase.firestore();
        db.collection("summary")
            .doc('data')
            .set({
                [name]: firebase.firestore.FieldValue.increment(n)
            }, {merge: true})
            .catch(e => {
            })
    }

    const actions = {
        onEdit: (data) => {
            setModel(data);
            setOpenEdit(true);
        },

        onDelete: (data) => {
            setModel(data);
            setOpenDelete(true);
        },
        onPaymentsRecord: (data) => {
            setRecordPayments([])
            setLoading(true)
            getPaymentRecord(data)
        }
    };

    const getPaymentRecord = (data) => {
        const db = firebase.firestore();
        db.collection("income")
            .orderBy("createdAt", "desc")
            .where('apartment.id', '==', data.id)
            .limit(25)
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    list.push(data);
                });
                setLoading(false)
                setModalRecordPayments(true)
                setRecordPayments(list)
            });
    }

    const onCreate = () => {
        setLoading(true);
        setOpen(false);
        const db = firebase.firestore();

       let previousDueAmount = model.previousDueAmount ? Number(model.previousDueAmount) : null;
       let previousDueMonths = model.previousDueAmount ? Number(model.previousDueMonths) : null;
       let previousDueLastPayment = model.previousDueAmount? model.previousDueLastPayment : null;

        db.collection("apartments")
            .add({
                createdBy: {
                    id: firebase.auth().currentUser.uid,
                    displayName: firebase.auth().currentUser.displayName,
                },
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                apartment: model.apartment,
                billingDay: model.billingDay,
                tenant: null,
                residential: model.residential,
                previousDueAmount: previousDueAmount,
                previousDueMonths: previousDueMonths,
                previousDueLastPayment: previousDueLastPayment,
                paymentStatus: 'pending',
                status: 'active',
                dueDate: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                onSummary('apartment', 1)
                setCAlert({open: true, type: "success", ms: "Apartamento Agregado Correctamente"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new ApartmentsModel());
            });
    };

    const onUpdate = () => {
        setLoading(true);
        setOpenEdit(false);
        const db = firebase.firestore();


        let previousDueAmount = model.previousDueAmount ? Number(model.previousDueAmount) : null;
        let previousDueMonths = model.previousDueAmount ? Number(model.previousDueMonths) : null;
        let previousDueLastPayment = model.previousDueAmount? model.previousDueLastPayment : null;

        db.collection("apartments")
            .doc(model.id)
            .update({
                updatedBy: {
                    id: firebase.auth().currentUser.uid,
                    displayName: firebase.auth().currentUser.displayName,
                },
                previousDueAmount: previousDueAmount,
                previousDueMonths: previousDueMonths,
                previousDueLastPayment: previousDueLastPayment,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                apartment: model.apartment,
                billingDay: model.billingDay,
                residential: model.residential,
            })
            .then(() => {
                setCAlert({open: true, type: "success", ms: "Datos Del Apartamento Actualizados"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new ApartmentsModel());
            });
    };

    const onDelete = () => {
        setOpenDelete(false);
        const db = firebase.firestore();
        db.collection("apartments")
            .doc(model.id)
            .update({
                status: 'deleted'
            })
            .then(() => {
                onSummary('apartment', -1)
                setCAlert({open: true, type: "success", ms: "Apartamento Eliminado"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new ApartmentsModel());
            });
    };

    const onChange = (e, select) => {
        if (!select) {
            const {name, value} = e.target;
            setModel((prevState) => ({...prevState, [name]: value}));
        } else {
            const selected = {...select};
            const name = selected.autocomplete;
            delete selected.autocomplete;
            setModel((prevState) => ({...prevState, [name]: selected.value || selected}));
        }
    };


    const filtersOnchange = (e, select) => {
        if (!select) {
            const {name, value} = e.target;
            setFiltersValues((prevState) => ({...prevState, [name]: value}));
        } else {
            const selected = {...select};
            const name = selected.autocomplete;
            delete selected.autocomplete;
            setFiltersValues((prevState) => ({...prevState, [name]: selected.value || selected}));
        }
    }

    const onFilters = () => {
        const db = firebase.firestore();
        if (filtersValues.paymentStatus.id === 'all') {
            db.collection("apartments")
                .orderBy("createdAt", "desc")
                .where('residential.id', '==', filtersValues.residential.id)
                .get()
                .then((docs) => {
                    const list = [];
                    docs.forEach((doc) => {
                        const data = doc.data();
                        data.id = doc.id;
                        list.push(data);
                    });
                    setFiltersData(list);
                });
        } else {
            db.collection("apartments")
                .orderBy("createdAt", "desc")
                .where('paymentStatus', '==', filtersValues.paymentStatus.id)
                .where('residential.id', '==', filtersValues.residential.id)
                .get()
                .then((docs) => {
                    const list = [];
                    docs.forEach((doc) => {
                        const data = doc.data();
                        data.id = doc.id;
                        list.push(data);
                    });
                    setFiltersData(list);
                });
        }
    }

    const apartmentsList = React.useMemo(() => {
        return (filtersData? filtersData : apartments).map((data) => {
            let monthsValue = 0

            if (data.dueDate){
                const date1 = DateTime.fromISO(new Date().toISOString())
                const date2 = DateTime.fromSeconds(data.dueDate.seconds)
                const diff = date1.diff(date2, ['months'])

                if (diff.months <= 0){
                    monthsValue = 0
                }
                if (diff.months > 0){
                    monthsValue = Math.ceil(diff.months)
                }
            }

            return {
                ...data,
                id: data.id,
                dueDate: data.dueDate ? DateTime.fromSeconds(data.dueDate.seconds).toFormat('DD') : '',
                monthsPending: monthsValue,
                previousDueAmount: data.previousDueAmount ?? 0,
                paymentStatus: returnLabel(data.paymentStatus),
                actions: <TableActions data={data} {...actions} />,
            }
        });

    }, [filtersData, apartments])

    const recordPaymentsList = React.useMemo(() => {
        return recordPayments.map((data) => ({
            ...data,
            id: data.id,
            createdAt: data.createdAt? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '',
            actions: <TableActions data={data} {...actions} />,
        }));

    }, [recordPayments])


    return {
        apartmentsList,
        tenants,
        residential,
        model,
        setModel,
        openDelete,
        openEdit,
        open,
        setOpen,
        setOpenDelete,
        setOpenEdit,
        cAlert,
        setCAlert,
        loading,
        onChange,
        onCreate,
        onUpdate,
        onDelete,
        filtersValues,
        filtersOnchange,
        onFilters,
        modalRecordPayments,
        setModalRecordPayments,
        recordPaymentsList
    };
};

export default useData;
