import React from "react";
import {useFirebase} from "react-redux-firebase";
import {TableActions} from "./components/Table.Actions";
import {AlertModel, FiltersModel, TenantsModel} from "../../model";
import ApiClient from '../../firebase/api'

const useData = () => {
    const firebase = useFirebase();
    const [model, setModel] = React.useState(new TenantsModel());
    const [cAlert, setCAlert] = React.useState(new AlertModel());
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openPassword, setOpenPassword] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [password, setPassword] = React.useState('');

    const [filtersValues, setFiltersValues] = React.useState(new FiltersModel());
    const [filtersData, setFiltersData] = React.useState([]);

    const [tenants, setTenants] = React.useState([]);
    const [residential, setResidential] = React.useState([]);
    const [apartments, setApartments] = React.useState([]);

    React.useEffect(() => {
        const db = firebase.firestore();
        db.collection("residential")
            .orderBy("residential", "asc")
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.label = data.residential;
                    list.push(data);
                });
                setResidential(list);
            });

        db.collection("tenants")
            .orderBy("createdAt", "desc")
            .onSnapshot((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    list.push(data);
                });
                setTenants(list);
            });
    }, []);


    React.useEffect(()=>{
        if (model.residential && model.residential.id){
            const db = firebase.firestore();
            db.collection("apartments")
                .where('residential.id', '==', model.residential.id)
                .orderBy("apartment", "asc")
                .get()
                .then((docs) => {
                    const list = [];
                    docs.forEach((doc) => {
                        const data = doc.data();
                        data.id = doc.id;
                        data.label = data.apartment;
                        list.push(data);
                    });
                    setApartments(list);
                });
        }
    },[model])

    const onSummary = (name, n) => {
        const db = firebase.firestore();
        db.collection("summary")
            .doc('data')
            .set({
                [name]: firebase.firestore.FieldValue.increment(n)
            }, {merge: true})
            .catch(e => {
            })
    }

    const actions = {
        onEdit: (data) => {
            setModel(data);
            setOpenEdit(true);
        },

        onDelete: (data) => {
            setModel(data);
            setOpenDelete(true);
        },
        onPassword: (data) => {
            setModel(data);
            setOpenPassword(true);
        }
    };

    const onCreate = () => {
        setLoading(true);
        setOpen(false);
        ApiClient.post('create/user', {
            createdBy: {
                id: firebase.auth().currentUser.uid,
                displayName: firebase.auth().currentUser.displayName,
            },
            password: model.password,
            firstName: model.firstName,
            lastName: model.lastName,
            tenant: `${model.firstName} ${model.lastName}`,
            phoneNumber: model.phoneNumber,
            userName: model.userName,
            email: `${model.userName}@yojpich.com`,
            mail: model.mail,
            comment: model.comment,
            residential: model.residential,
            apartment: model.apartment,
        }).then((res) => {
            const db = firebase.firestore();
            db.collection("apartments")
                .doc(model.apartment.id)
                .update({
                    tenant: {
                        id:res.data.id,
                        label:`${model.firstName} ${model.lastName}`,
                        mail: model.mail,
                        phone: model.phoneNumber
                    },
                }).catch(() => {})
            onSummary('users', 1)
            setCAlert({open: true, type: "success", ms: "Propietario Creado Correctamente"});
        }).catch((e) => {
            setCAlert({open: true, type: "error", ms: e.message});
        }).finally(() => {
            setLoading(false);
            setModel(new TenantsModel());
        });
    };

    const onUpdate = () => {
        setLoading(true);
        setOpenEdit(false);
        const db = firebase.firestore();
        db.collection("tenants")
            .doc(model.id)
            .update({
                updatedBy: {
                    id: firebase.auth().currentUser.uid,
                    displayName: firebase.auth().currentUser.displayName,
                },
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                tenant: model.tenant,
                phoneNumber: model.phoneNumber,
                mail: model.mail,
                comment: model.comment,
                residential: model.residential,
                apartment: model.apartment,
            })
            .then(() => {
                const db = firebase.firestore();
                db.collection("apartments")
                    .doc(model.apartment.id)
                    .update({
                        tenant: {
                            id:model.id,
                            label:`${model.firstName} ${model.lastName}`,
                            mail: model.mail,
                            phone: model.phoneNumber
                        },
                    }).catch(() => {})
                setCAlert({open: true, type: "success", ms: "Datos Del Propietario Actualizados"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new TenantsModel());
            });
    };

    const onDelete = () => {
        setOpenDelete(false);
        const db = firebase.firestore();
        db.collection("tenants")
            .doc(model.id)
            .delete()
            .then(() => {
                onSummary('users', -1)
                setCAlert({open: true, type: "success", ms: "Propietario Eliminado"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new TenantsModel());
            });
    };

    const onChangePassword = () => {
        setOpenPassword(false)
        setLoading(true);
        ApiClient.post(`changePassword/${model.id}`, {
            password: password,
        }).then((e) => {
            setCAlert({open: true, type: "success", ms: "Contraseña Actulizada Correctamente"});
        }).catch((e) => {
            setCAlert({open: true, type: "error", ms: e.message});
        }).finally(() => {
            setLoading(false);
            setModel(new TenantsModel());
            setPassword('')
        });
    }

    const onChange = (e, select) => {
        if (!select) {
            const {name, value} = e.target;
            setModel((prevState) => ({...prevState, [name]: value}));
        } else {
            const selected = {...select};
            const name = selected.autocomplete;
            delete selected.autocomplete;
            setModel((prevState) => ({...prevState, [name]: selected}));
        }
    };

    const filtersOnchange = (e, select) => {
        if (!select) {
            const {name, value} = e.target;
            setFiltersValues((prevState) => ({...prevState, [name]: value}));
        } else {
            const selected = {...select};
            const name = selected.autocomplete;
            delete selected.autocomplete;
            setFiltersValues((prevState) => ({...prevState, [name]: selected.value || selected}));
        }
    }

    const onFilters = () => {
        const db = firebase.firestore();
        db.collection("tenants")
            .orderBy("createdAt", "desc")
            .where('residential.id', '==', filtersValues.residential.id)
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    list.push(data);
                });
                setFiltersData(list);
            });
    }

    const tenantsList = React.useMemo(() => {
        return (filtersData.length > 0 ? filtersData : tenants).map((data) => ({
            ...data,
            id: data.id,
            actions: <TableActions data={data} {...actions} />,
        }));

    }, [filtersData, tenants])

    return {
        apartments,
        tenantsList,
        tenants,
        residential,
        model,
        setModel,
        openDelete,
        openEdit,
        open,
        setOpen,
        setOpenDelete,
        setOpenEdit,
        cAlert,
        setCAlert,
        loading,
        onChange,
        onCreate,
        onUpdate,
        onDelete,
        filtersValues,
        filtersOnchange,
        onFilters,
        openPassword,
        setOpenPassword,
        password,
        setPassword,
        onChangePassword,
    };
};

export default useData;
