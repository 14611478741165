import React from "react";
import IconButton from "@mui/material/IconButton";
import {jsPDF} from "jspdf";
import logo from "../../../assets/logo.png";
import {DateTime} from "luxon";
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Typography, ListItemIcon } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import { useMaterialUIController } from "../../../context";
import {BUSINESS_PHONE} from "../../../config";

export function TableActions({data, onEdit, onDelete, onSendMail}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const handleClose = (data, type) => {
        setAnchorEl(null);
        if (type === "edit") {
            onEdit(data);
        }
        if (type === "print") {
            PdfIncome(data);
        }
        if (type === "delete") {
            onDelete(data);
        }

        if (type === "mail") {
            onSendMail(data);
        }
    };



    var formatter = new Intl.NumberFormat('es-do', {
        style: 'currency',
        currency: 'DOP',
    });

    function PdfIncome(data) {
        const doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true,
            floatPrecision: 16,
            compress: true,
        });

        doc.addImage(logo, "JPEG", 15, 40, 40, 40);
        doc.text('Fecha: ' + DateTime.fromSeconds(data.createdAt.seconds).toFormat('dd-MM-yyyy'), 15, 90);
        doc.text(`Teléfono: ${BUSINESS_PHONE}`, 15, 100);

        doc.text('Cliente: ' + data.tenant.label, 190, 80, null, null, "right");
        doc.text('Residencial: ' + data.residential.label, 190, 90, null, null, "right");
        doc.text('Apartamento: ' + data.apartment.label, 190, 100, null, null, "right");

        doc.text("Concepto: Pago deuda anterior", 105, 130, null, null, "center");

        doc.text("Total pagado: " + formatter.format(data.amount), 105, 140, null, null, "center");

        doc.save(`Factura-${DateTime.fromSeconds(data.createdAt.seconds).toFormat('dd-MM-yyyy')}.pdf`);
    }

    return <>

        <IconButton
            style={{ padding: 0, margin: 0 }}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            aria-controls={open ? "basic-menu" : undefined}
            onClick={handleClick}
        >
            <MoreVertIcon style={{ color: darkMode ? "#fff" : "#000" }} />
        </IconButton>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            {/*<MenuItem onClick={() => handleClose(data, 'edit')} style={{ marginBottom: 10 }}>*/}
            {/*    <ListItemIcon>*/}
            {/*        <EditIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />*/}
            {/*    </ListItemIcon>*/}
            {/*    <Typography variant="inherit">Editar</Typography>*/}
            {/*</MenuItem>*/}

            <MenuItem onClick={() => handleClose(data,"print")} style={{ marginBottom: 10 }}>
                <ListItemIcon>
                    <PrintIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
                </ListItemIcon>
                <Typography variant="inherit">Imprimir</Typography>
            </MenuItem>

            <MenuItem onClick={() => handleClose(data,"mail")} style={{ marginBottom: 10 }}>
                <ListItemIcon>
                    <EmailIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
                </ListItemIcon>
                <Typography variant="inherit">Enviar factura por correo</Typography>
            </MenuItem>

            <MenuItem onClick={() => handleClose(data,"delete")} style={{ marginBottom: 10 }}>
                <ListItemIcon>
                    <DeleteIcon fontSize="small" style={{ color: darkMode ? "#fff" : "#000" }} />
                </ListItemIcon>
                <Typography variant="inherit">Eliminar</Typography>
            </MenuItem>

        </Menu>

    </>
}
