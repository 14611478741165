import React from "react";
import {useFirebase} from "react-redux-firebase";
import {TableActions} from "./components/Table.Actions";
import {AlertModel, ExpensesModel, FiltersModel} from "../../model";
import {DateTime} from "luxon";
import {returnLabel} from "../../utilities/constants";

const useData = () => {
    const firebase = useFirebase();
    const [model, setModel] = React.useState(new ExpensesModel());
    const [filtersValues, setFiltersValues] = React.useState(new FiltersModel());
    const [cAlert, setCAlert] = React.useState(new AlertModel());
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [expenses, setexpenses] = React.useState([]);
    const [residential, setResidential] = React.useState([]);
    const [bank, setBank] = React.useState([]);
    const [provider, setProvider] = React.useState([]);
    const [filtersData, setFiltersData] = React.useState(null);

    React.useEffect(() => {
        const db = firebase.firestore();
        db.collection("expenses")
            .orderBy("createdAt", "desc")
            .onSnapshot((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    list.push(data);
                });
                setexpenses(list);
            });
        db.collection("residential")
            .orderBy("residential", "asc")
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.label = data.residential;
                    list.push(data);
                });
                setResidential(list);
            });
        db.collection("bank")
            .orderBy("bank", "asc")
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.label = data.bank;
                    list.push(data);
                });
                setBank(list);
            });
        db.collection("provider")
            .orderBy("provider", "asc")
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.label = data.provider;
                    list.push(data);
                });
                setProvider(list);
            });
    }, []);

    const actions = {
        onEdit: (data) => {
            setModel(data);
            setOpenEdit(true);
        },

        onDelete: (data) => {
            setModel(data);
            setOpenDelete(true);
        },
    };

    const onCreate = () => {
        setLoading(true);
        setOpen(false);
        const db = firebase.firestore();

        let dataPayment = {
            paymentStatus: model.paymentStatus
        }
        if (model.paymentStatus.id === 'completed') {
            dataPayment.completedAt = firebase.firestore.FieldValue.serverTimestamp()
        }

        db.collection("expenses")
            .add({
                createdBy: {
                    id: firebase.auth().currentUser.uid,
                    displayName: firebase.auth().currentUser.displayName,
                },
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                residential: model.residential,
                paymentStatus: model.paymentStatus,
                concept: model.concept,
                amount: Number(model.amount),
                bank: model.bank,
                provider: model.provider,
                ...dataPayment
            })
            .then(() => {
                setCAlert({open: true, type: "success", ms: "Gasto Agregado Correctamente"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new ExpensesModel());
            });
    };
    const onUpdate = () => {
        setLoading(true);
        setOpenEdit(false);
        const db = firebase.firestore();

        let dataPayment = {
            paymentStatus: model.paymentStatus
        }
        if (model.paymentStatus.id === 'completed') {
            dataPayment.completedAt = firebase.firestore.FieldValue.serverTimestamp()
        }

        db.collection("expenses")
            .doc(model.id)
            .update({
                updatedBy: {
                    id: firebase.auth().currentUser.uid,
                    displayName: firebase.auth().currentUser.displayName,
                },
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                residential: model.residential,
                paymentStatus: model.paymentStatus,
                concept: model.concept,
                bank: model.bank,
                provider: model.provider,
                ...dataPayment
            })
            .then(() => {
                setCAlert({open: true, type: "success", ms: "Datos De Gasto Actualizado"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new ExpensesModel());
            });
    };

    const onDelete = () => {
        setOpenDelete(false);
        const db = firebase.firestore();
        db.collection("expenses")
            .doc(model.id)
            .delete()
            .then(() => {
                setCAlert({open: true, type: "success", ms: "Gasto Eliminado"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new ExpensesModel());
            });
    };

    const onChange = (e, select) => {
        if (!select) {
            const {name, value} = e.target;
            setModel((prevState) => ({...prevState, [name]: value}));
        } else {
            const selected = {...select};
            const name = selected.autocomplete;
            delete selected.autocomplete;
            setModel((prevState) => ({...prevState, [name]: selected}));
        }
    };

    const filtersOnchange = (e, select) => {
        if (!select) {
            const {name, value} = e.target;
            setFiltersValues((prevState) => ({...prevState, [name]: value}));
        } else {
            const selected = {...select};
            const name = selected.autocomplete;
            delete selected.autocomplete;
            setFiltersValues((prevState) => ({...prevState, [name]: selected.value || selected}));
        }
    }

    const onFilters = () => {
        const db = firebase.firestore();
        let from = DateTime.fromFormat(filtersValues.from, 'yyyy-MM-dd').toJSDate()
        let to = DateTime.fromFormat(filtersValues.to, 'yyyy-MM-dd').toJSDate()
        if (filtersValues.paymentStatus.id === 'all') {
            db.collection("expenses")
                .where("createdAt", ">=", from)
                .where("createdAt", "<=", to)
                .where('residential.id', '==', filtersValues.residential.id)
                .get()
                .then((docs) => {
                    const list = [];
                    docs.forEach((doc) => {
                        const data = doc.data();
                        data.id = doc.id;
                        list.push(data);
                    });
                    setFiltersData(list);
                });
        } else {
            db.collection("expenses")
                .where("createdAt", ">=", from)
                .where("createdAt", "<=", to)
                .where('residential.id', '==', filtersValues.residential.id)
                .where('paymentStatus.id', '==', filtersValues.paymentStatus.id)
                .get()
                .then((docs) => {
                    const list = [];
                    docs.forEach((doc) => {
                        const data = doc.data();
                        data.id = doc.id;
                        list.push(data);
                    });
                    setFiltersData(list);
                });
        }
    }


    const expensesList = React.useMemo(() => {
        return (filtersData? filtersData : expenses).map((data) => ({
            ...data,
            id: data.id,
            createdAt: data.createdAt? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '',
            paymentStatus: returnLabel(data.paymentStatus.id),
            actions: <TableActions data={data} {...actions} />,
        }));

    }, [filtersData, expenses])

    return {
        bank,
        residential,
        expenses,
        expensesList,
        provider,
        model,
        setModel,
        openDelete,
        openEdit,
        open,
        setOpen,
        setOpenDelete,
        setOpenEdit,
        cAlert,
        setCAlert,
        loading,
        onChange,
        onCreate,
        onUpdate,
        onDelete,
        filtersValues,
        filtersOnchange,
        onFilters
    };
};

export default useData;
