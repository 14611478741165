import React from "react";
import Icon from "@mui/material/Icon";
import MDButton from "../../../components/MDButton";

export function TableActions({ data, onEdit, onDelete }) {
  return [
    <MDButton
      onClick={() => onEdit(data)}
      key={2}
      style={{ marginRight: 2 }}
      variant="gradient"
      color="warning"
      size="small"
    >
      <Icon>edit</Icon>
    </MDButton>,
    <MDButton onClick={() => onDelete(data)} key={3} variant="gradient" color="error" size="small">
      <Icon>delete</Icon>
    </MDButton>,
  ];
}
