import React from "react";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
export function TableActions({ data, onEdit, onDelete }) {
  return [
    <IconButton
      onClick={() => onEdit(data)}
      key={2}
      style={{ marginRight: 2 }}
      variant="gradient"
      color="warning"
      size="medium"
    >
      <Icon>edit</Icon>
    </IconButton>,
    <IconButton onClick={() => onDelete(data)} key={3} variant="gradient" color="error" size="medium">
      <Icon>delete</Icon>
    </IconButton>,
  ];
}
