import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Modal, Typography } from "@mui/material";
import React from "react";
import {modalStyle} from "../../../utilities/constants";

export default function DeleteModal({ open, handleClose, model, onDelete }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          ELIMINAR
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          ¿Está seguro de que desea eliminar este residencial?
        </Typography>
        <br />
        <MDButton style={{ marginRight: 5 }} color="success" onClick={onDelete}>
          Eliminar
        </MDButton>
        <MDButton onClick={handleClose} color="error">
          Cancelar
        </MDButton>
      </MDBox>
    </Modal>
  );
}
