import React from "react";
import {useFirebase} from "react-redux-firebase";
import {TableActions} from "./components/Table.Actions";
import {AlertModel, FiltersModel, GasModel} from "../../model";
import {DateTime} from "luxon";
import {returnLabel} from "../../utilities/constants";

const useData = () => {
    const firebase = useFirebase();
    const [model, setModel] = React.useState(new GasModel());
    const [cAlert, setCAlert] = React.useState(new AlertModel());
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [gas, setGas] = React.useState([]);
    const [bank, setBank] = React.useState([]);
    const [residential, setResidential] = React.useState([]);
    const [apartments, setApartments] = React.useState([]);
    const [filtersValues, setFiltersValues] = React.useState(new FiltersModel());
    const [filtersData, setFiltersData] = React.useState(null);


    React.useEffect(() => {
        const db = firebase.firestore();
        db.collection("gas")
            .orderBy("createdAt", "desc")
            .limit(200)
            .onSnapshot((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    list.push(data);
                });
                setGas(list);
            });
        db.collection("residential")
            .orderBy("residential", "asc")
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.label = data.residential;
                    list.push(data);
                });
                setResidential(list);
            });
        db.collection("bank")
            .orderBy("bank", "asc")
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.label = data.bank;
                    list.push(data);
                });
                setBank(list);
            });
    }, []);

    React.useEffect(()=>{
        if (model.residential && model.residential.id){
            const db = firebase.firestore();
            db.collection("apartments")
                .where('residential.id', '==', model.residential.id)
                .orderBy("apartment", "asc")
                .get()
                .then((docs) => {
                    const list = [];
                    docs.forEach((doc) => {
                        const data = doc.data();
                        data.id = doc.id;
                        data.label = data.apartment;
                        list.push(data);
                    });
                    setApartments(list);
                });
        }
    },[model])

    const actions = {
        onEdit: (data) => {
            let d = {...data}
            d.paymentStatus = {
                id: data.paymentStatus,
                label: data.paymentStatus === 'pending' ? 'Pendiente' : 'Completado'
            }
            setModel(d);
            setOpenEdit(true);
        },

        onDelete: (data) => {
            setModel(data);
            setOpenDelete(true);
        },
    };

    const handleClose = () => {
        setModel(new GasModel())
        setOpen(false)
        setOpenDelete(false)
        setOpenEdit(false)
    }

    const onCreate = () => {
        setLoading(true);
        setOpen(false);
        const db = firebase.firestore();

        let dataPayment = {
            paymentStatus: model.paymentStatus.id,
        }
        if (model.paymentStatus.id === 'completed') {
            dataPayment.completedAt = firebase.firestore.FieldValue.serverTimestamp()
        }

        db.collection("gas")
            .add({
                createdBy: {
                    id: firebase.auth().currentUser.uid,
                    displayName: firebase.auth().currentUser.displayName,
                },
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                residential: model.residential,
                tenant: model.apartment.tenant,
                apartment: {id: model.apartment.id, label: model.apartment.label},
                gallonsQty: Number(model.gallonsQty),
                bank: model.bank,
                amount: Number(model.price) * Number(model.gallonsQty),
                price: Number(model.price),
                ...dataPayment
            })
            .then(() => {
                setCAlert({open: true, type: "success", ms: "Consumo De Gas Agregado"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new GasModel());
            });
    };

    const onUpdate = () => {
        setLoading(true);
        setOpenEdit(false);
        const db = firebase.firestore();


        let dataPayment = {
            paymentStatus: model.paymentStatus.id,
        }
        if (model.paymentStatus.id === 'completed') {
            dataPayment.completedAt = firebase.firestore.FieldValue.serverTimestamp()
        }

        db.collection("gas")
            .doc(model.id)
            .update({
                updatedBy: {
                    id: firebase.auth().currentUser.uid,
                    displayName: firebase.auth().currentUser.displayName,
                },
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                residential: model.residential,
                apartment: {id: model.apartment.id, label: model.apartment.label},
                tenant: model.tenant,
                gallonsQty: model.gallonsQty,
                bank: model.bank,
                amount: model.amount,
                price: model.price,
                ...dataPayment
            })
            .then(() => {
                setCAlert({open: true, type: "success", ms: "Datos Del Consumo Actualizado"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new GasModel());
            });
    };

    const onDelete = () => {
        setOpenDelete(false);
        const db = firebase.firestore();
        db.collection("gas")
            .doc(model.id)
            .delete()
            .then(() => {
                setCAlert({open: true, type: "success", ms: "Consumo Eliminado"});
            })
            .catch((e) => {
                setCAlert({open: true, type: "error", ms: e.message});
            })
            .finally(() => {
                setLoading(false);
                setModel(new GasModel());
            });
    };

    const onChange = (e, select) => {
        if (!select) {
            const {name, value} = e.target;
            setModel((prevState) => ({...prevState, [name]: value}));
        } else {
            const selected = {...select};
            const name = selected.autocomplete;
            delete selected.autocomplete;
            setModel((prevState) => ({...prevState, [name]: selected}));
        }
    };

    const filtersOnchange = (e, select) => {
        if (!select) {
            const { name, value } = e.target;
            setFiltersValues((prevState) => ({ ...prevState, [name]: value }));
        } else {
            const selected = { ...select };
            const name = selected.autocomplete;
            delete selected.autocomplete;
            setFiltersValues((prevState) => ({ ...prevState, [name]: selected.value || selected }));
        }
    }

    const onFilters = () => {
        const db = firebase.firestore();
        let from = DateTime.fromFormat(filtersValues.from, 'yyyy-MM-dd').toJSDate()
        let to = DateTime.fromFormat(filtersValues.to, 'yyyy-MM-dd').toJSDate()
        db.collection("gas")
            .where("createdAt", ">=", from)
            .where("createdAt", "<=", to)
            .where('residential.id', '==', filtersValues.residential.id)
            .where('paymentStatus', '==', filtersValues.paymentStatus.id)
            .get()
            .then((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    list.push(data);
                });
                setFiltersData(list);
            });
    }

    const gasList = React.useMemo(() => {
        return (filtersData ? filtersData : gas).map((data) => ({
            ...data,
            id: data.id,
            createdAt: data.createdAt ? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '',
            paymentStatus: returnLabel(data.paymentStatus),
            actions: <TableActions data={data} {...actions} />,
        }));

    }, [filtersData, gas])

    return {
        residential,
        gas,
        bank,
        gasList,
        apartments,
        model,
        setModel,
        openDelete,
        openEdit,
        open,
        setOpen,
        setOpenDelete,
        setOpenEdit,
        cAlert,
        setCAlert,
        loading,
        onChange,
        onCreate,
        onUpdate,
        onDelete,
        filtersValues,
        filtersOnchange,
        onFilters,
        handleClose
    };
};

export default useData;
